nav {
    background-color: #05396b;
    width: calc(100%);
    padding: 1em 15%;
    box-sizing: border-box;

    display: flex;
    justify-content: space-between;
    align-items: center;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
}

nav>a>img {
    height: 45px;
}

nav>a {
    font-size: 0;
}
select {
    border: 2px solid white;
    box-sizing: border-box;
    border-radius: 0.6rem;
    align-self: center;
    text-align: center;
    color: aliceblue;
    cursor: pointer;
    padding: 0.5em;
    background-color: transparent;
}

.flex-row-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
}




nav:after {
    content: "";
    width: 100%;
    height: 77px;
    margin-top: 77px;
    /* margin-left: -75px; */
    display: block;
    position: absolute;
    /* left: 50%; */
    z-index: -4;
    -webkit-box-shadow: 0px 0px 8px 2px #000000;
    -moz-box-shadow: 0px 0px 8px 2px #000000;
    box-shadow: 0px 0px 8px 2px #000000;
    left: 0;
    top: -77px;
}


select:focus {
    outline: none !important;
}

@media (max-width: 600px) {
    a > div > button.button {
        display: none;
    }
}