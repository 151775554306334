.about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 1em;
    color: white;
}

/* .member {
    display: flex;
    gap: 2em;
    width: 100%;
} */

img#team-photo {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 1em
}

.about > h1 {
    color: white;
}


/* */

.members {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2em;
}

.member {
    display: flex;
    gap: 2em;
}

.member > p {
    width: 200px;
    height: 200px;
    margin-left: calc(-200px - 2em);
    z-index: -1;
}

/* .member:not(.team):hover p{
    margin-left: 0;
    z-index: 0;
} */

.member.team p{
    margin-left: 0;
    z-index: 0;
}