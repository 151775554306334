section {
    min-height: 200px;
    background: rgb(3, 48, 92);
    background: linear-gradient(135deg, rgba(3, 48, 92, 1) 0%, rgba(5, 57, 107, 1) 25%);
    border-radius: 1em;
    padding: 2em 2em;
    color: white;

    display: flex;
    flex-direction: row;
    gap: 1em;
    justify-content: center;
    align-items: center;
}

div#root section:nth-of-type(1) {
    padding: 120px 2em 2em 2em;
}

div#root section:nth-of-type(1)>div {
    border: 1px solid #ffffff38;

    width: calc(100% / 3);
    border-radius: 0.5em;
    padding: 1em;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 17em;
}

div#root section:nth-of-type(1)>div:hover {
    transform: scale(1.05);
    background: rgba(255, 255, 255, 0.18);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

div#root section:nth-of-type(1)>div>h3 {
    font-size: large;
}

section>div>h3,
iframe,
section {
    margin-bottom: 1em;
}

.swiper-slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.swiper-slide>img, .swiper-slide>svg {
    height: 2rem;
}

section#contact, section#success {
    display: flex;
    flex-direction: column;
    gap: 2em;
}

section#success > div {
    width: 100%;
}

main {
    position: relative;
}

main > p {
    position: absolute;
    left: 0;
    color: white;
    bottom: 50%;
    width: 40vw;
    font-size: larger;
    line-height: 1.75em;
    font-weight: 600;
}

main > p > div {
    display: inline;
    color: #05396b;
    background-color: lightgray;
    border-radius: 0.25em;
    padding: 0 0.5rem;
}

#successes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4em;
}

.technology-chips {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
}

.technology-chip {
    border-radius: 1em;
    background-color: white;
    line-height: 2em;
    color: black;
    padding: 0 1em;
    width: fit-content;
    border-radius: 1em; 
    background-color: white;
}

.project-presentation {
    width: calc(50% - 2em);
    display: flex;
    flex-direction: column;
    gap: 2em;
}


.success {
    display: flex;
    width: 100%;
}

.success-item {
    width: calc(100% / 6);
}

.success-item > img {
    width: 100%;
}

.swiper-wrapper {
    margin: auto;
    width: 100%;
    display: flex;
    align-items: stretch;
}

.swiper-slide {
    gap: 1em;
    text-align: center;
}

.project {
    display: flex;
    width: calc(100% - 2em);
    gap: 2em;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.project > img {
    width: 50%;
    /* height: fit-content; */
}

@media (max-width: 700px) {
    main > p {
        width: calc(100vw - 30%);
    }

    div#root::after {
        content: "";
        z-index: -1;
        background: rgba(5, 58, 107, 0.658);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        -webkit-backdrop-filter: blur(5.9px);
        backdrop-filter: blur(7px);
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        position: absolute;
    }

}

@media (max-width: 1200px) {
    .project > img {
        width: 100%;
    }

    .project-presentation {
        width: calc(100% - 2em);
    }
}

@media (max-width: 600px) {
    div#root section:nth-of-type(1) {
        flex-direction: column;
    }

    div#root section:nth-of-type(1)>div {
        width: 100%;
    }

    main > p {
        width: calc(100vw - 10%);
        text-align: center;
    }
}

@media (min-width: 1400px) {
    main > p {
        font-size: xx-large;
    }
}