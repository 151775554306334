body {
    --activeclr: #05396b;
}

@property --activeclr {
    syntax: "<color>";
    initial-value: #05396b;
    inherits: true;
}

.navbar {
    display: flex;
    justify-content: space-between;
    height: 4rem;
    background: white;
    padding: 0 0.75rem;
    transition: padding 300ms ease;
    position: fixed;
    bottom: 1em;
    left: 0;
    right: 0;
    margin: auto;
    width: fit-content;
    z-index: 1;
    border-radius: 2rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.navitem {
    position: relative;
    display: grid;
    place-content: center;
    color: #fff;
    width: 4.5rem;
    text-align: center;
    color: black;
    font-size: 1rem;
    transition: width 300ms ease, flex-grow 300ms ease;
    text-decoration: none;

    justify-content: center;
    align-items: center;
    gap: 0.25em;
}

.navitem > p {
    font-size: 0.65em;
    background: none;
    bottom: 0.4em;
    margin: auto;
    left: 0;
    right: 0;
}

.hoverindicator {
    position: absolute;
    width: 2.5rem;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    background: var(--activeclr);
    opacity: 0;
    border-radius: 0.5rem;
    height: 0.25rem;
    inset: auto auto -0.25rem 50%;
    transition: inset 300ms ease, opacity 300ms ease;
}

@media (hover: hover) {
    .navitem:not(.active):hover .hoverindicator {
        border-radius: 1.25rem;
        height: 2.5rem;
        opacity: 1;
        transition: opacity 150ms ease;
        z-index: -1;
        transform: translate(-50%, -1rem);
        color: white;
    }

    .navitem:not(.active):hover i {
        color: white;
    }

    .navitem:not(.active):hover > p {
        display: none;
    }
}

/* .navitem.active > p {
    display: none;
} */

.active .hoverindicator {
    opacity: 1;
    border-radius: 0.5rem;
    height: 0.25rem;
    inset: auto auto 0.25rem 50%;
    transition: inset 300ms ease, height 300ms ease, border-radius 300ms ease;
}

.navitem > i {
    width: 1em;
    height: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}