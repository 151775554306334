* {
    margin: 0;
}

body {
    margin-top: 80px;
    margin-bottom: 100px;
    margin-left: 15%;
    margin-right: 15%;
    min-height: calc(100vh - 80px - 100px);
    background-color: #060625;
}

h1 {
    color: #05396b;
}

.button {
    border: 2px solid white;
}

/* .button:hover {
    color: blue;
    border: 2px solid blue;
    box-shadow: 0 0 5px blue;
} */


button {
    position: relative;
    background: transparent;
    box-sizing: border-box;
    border-radius: 0.6rem;
    align-self: center;
    text-align: center;
    color: aliceblue;
    cursor: pointer;
    padding: 0.5em;
}

.buttonBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.buttonBox > button {
    text-transform: uppercase;
}

@media (max-width:600px) {
    body {
        margin-left: 5%;
        margin-right: 5%;
    }
}

main {
    height: calc(100vh - 77px - 4rem - 3em);
}


img.background {
    position: absolute;
    z-index: -1;
    height: 100vh;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    object-fit: cover;
}


/* FORMS */


form>input,
form>textarea {
    width: 80%;
    width: 80%;
    border-radius: 0.5rem;
    color: #05396b;
    padding: 0.25em;
    background-color: #ffffffe3;
    border: unset;
    padding-left: 1rem;
}

form>input:focus,
form>textarea:focus,
form>div>input:focus,
form>div>textarea:focus {
    outline: none !important;
}


form>textarea,
form>div>textarea {
    height: 8rem;
}