.contact-section {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    gap: 2rem;
    width: 100%;
}

.contact-section>form,
.contact-section>div {
    width: calc(50% - 1rem - 2em);
}

.contact-section>form>.buttonBox {
    align-self: flex-end;
    margin-right: 10%;
}

.contact-section>div {
    border-radius: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 1em;
}

.contact-section>div > a > i {
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-section>div > a > i::before {
    font-size: 2em;
    margin-right: 1em;
}

a {
    text-decoration: none;
    color: white;
}
form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
}


@media (max-width: 900px) {

    .contact-section>form,
    .contact-section>div {
        width: 100%;
    }
}

.social-media {
    display: flex;
    flex-direction: row;
    gap: 1em;
    font-size: 2em;
}

.contact-section div:nth-of-type(){
    display: flex;
    flex-direction: column;
}

.buttonBox {
    width: 100%;
}


.last-form-line {
    display: flex;
    justify-content: center;
    align-items: stretch;
}