.estimate-section {
    padding: 2.5em 0;
    display: flex;
    flex-direction: row-reverse;
    color: white;
    height: calc(100vh - 80px - 4rem - 1em - 5em - 2em);
}

.estimate-section textarea,
input {
    border: unset;
    border-radius: 0.25em;
    line-height: 2em;
}

.estimate-section .button {
    width: 100%;
}

.estimate-section::before {
    content: "";
    z-index: -1;
    background: rgba(5, 58, 107, 0.658);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(5.9px);
    backdrop-filter: blur(7px);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
}

.estimate-section>form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2em;
}

.form-checkbox-container,
.form-checkbox-container>div {
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.form-checkbox-container>div>label>i {
    font-size: 1.5em;
    padding: 0.5em;
}

.form-checkbox-container,
.form-data-container,
.form-details-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
}



/* budget style */

#budget {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2em;
}

.card {
    width: calc(100% - 2em);
    height: 3em;
    font-size: 0.75em;
    display: inline-block;
    position: relative;
}

input[type="radio"] {
    display: none;
}

input[type="radio"]:checked+label {
    background-color: #05396b;
    color: #fff;
}

.card>label:hover {
    background-color: #05396b;
    color: #fff;
}


.card>label {
    display: block;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #f1f1f1;
    border-radius: 0.25em;
    color: #05396b;
}


div.form-details-container>textarea {
    height: 14rem;
}


@media (max-width:800px) {
    .estimate-section>form {
        grid-template-columns: 1fr 1fr;
    }
    .estimate-section {
        height: auto;
    }
}

@media (max-width:700px) {
    .estimate-section>form {
        grid-template-columns: 1fr;
    }

    .estimate-section {
        height: auto;
    }
}